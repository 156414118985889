<template>
  <div class="default-modal order-reason-change-model">
    <el-dialog
      top="25vh"
      :width="currentWidth"
      v-model="visible"
      :title="displayTitle"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="dialog-header">
        <div class="header-part">
          <span v-html="displayTitleChanged"></span>
        </div>
        <el-button
          size="medium"
          class="el-white"
          type="info"
          plain
          @click="close"
        >
          <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon" />
        </el-button>
      </div>
      <div class="popup-date pb-0">
        <el-form ref="ruleForm" :model="formData">
          <el-form-item prop="selectedReasons">
            <el-select
              v-model="formData.selectedReasons"
              placeholder="Choose reasons"
              multiple
              filterable
              allow-create
              default-first-option
              @change="updateNotes"
            >
              <el-option
                v-for="reason in predefinedReasons"
                :key="reason.value"
                :label="reason.label"
                :value="reason.value"
              />
            </el-select>
          </el-form-item>
          <br />
          <el-form-item prop="notes">
            <el-input
              v-model="formData.notes"
              type="textarea"
              rows="3"
              placeholder="Enter notes"
            />
            <p><b>Note :</b> Minimum 3 characters are required</p>
          </el-form-item>
        </el-form>
        <div class="dialog-footer pt-0">
          <el-button
            class="blue-save-button"
            :disabled="checkReason"
            @click="submit('ruleForm')"
          >
            SUBMIT
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
    },
    width: {
      default: 30,
    },
  },
  data() {
    return {
      displayTitle: this.displayTitleChanged,
      visible: true,
      currentWidth: "30%",
      formData: {
        selectedReasons: [], // New property for selected reasons (multiple)
        notes: "",
      },
      predefinedReasons: [
        {
          value: "Insufficient sample received",
          label: "Insufficient sample received",
        },
        {
          value: "Incorrect specimen received",
          label: "Incorrect specimen received",
        },
        { value: "Turnaround time", label: "Turnaround time" },

        {
          value: "Specimen received without requisition",
          label: "Specimen received without requisition",
        },
        { value: "Early collection", label: "Early collection" },
        {
          value: "Only requisition received without sample",
          label: "Only requisition received without sample",
        },
        {
          value: "Sample has leaked in transit",
          label: "Sample has leaked in transit",
        },
        {
          value: "Hemolyzed or clotted specimen",
          label: "Hemolyzed or clotted specimen",
        },
        { value: "Invalid insurance", label: "Invalid insurance" },
        {
          value: "Inactive insurance",
          label: "Inactive insurance",
        },
        {
          value: "Invalid ICD codes",
          label: "Invalid ICD codes",
        },
        {
          label: "The date of collection is missing in the requisition",
          value: "The date of collection is missing in the requisition",
        },
        {
          label: "ICD codes are missing in the requisition",
          value: "ICD codes are missing in the requisition.",
        },
        {
          value: "The test is not marked correctly",
          label: "The test is not marked correctly",
        },
        {
          value: "Specimen and test mismatch",
          label: "Specimen and test mismatch",
        },
        {
          value: "Medications are needed",
          label: "Medications are needed",
        },
        {
          value: "Insurance details are missing",
          label: "Insurance details are missing",
        },
        {
          value: "There is a billing mismatch",
          label: "There is a billing mismatch",
        },
        // Add more predefined reasons as needed
      ],
      reasonToActionMap: {
        "Insufficient sample received":
          "Please provide a specimen with sufficient quantity for processing the test.",
        "Incorrect specimen received":
          "Please provide the correct specimen type related to the test ordered.",
        "Turnaround time":
          "Please minimize the time delay between specimen collection and specimen receipt.",
        "Specimen received without requisition":
          "Please provide the requisition form to process the testing.",
        "Only requisition received without sample":
          "Please provide the sample for test processing along with the updated requisition.",
        "Sample has leaked in transit":
          "Please provide a fresh sample to process the test.",
        "Hemolyzed or clotted specimen":
          "Please recollect the sample as it needs to be in proper condition for testing.",
        "Invalid insurance":
          "Please provide valid insurance details along with a copy of the insurance.",
        "Inactive insurance":
          "Please provide active insurance details along with a copy of the insurance.",
        "Invalid ICD codes":
          "Please provide valid ICD codes to process the test.",
        "The date of collection is missing in the requisition":
          "We need the date of collection to process the test.",
        "ICD codes are missing in the requisition":
          "Please provide the ICD code for test processing.",
        "The test is not marked correctly":
          "We need an updated requisition to process the testing.",
        "Specimen and test mismatch":
          "The specimen and requisition are incompatible.",
        "Medications are needed":
          "We require the medications for test processing.",
        "Insurance details are missing":
          "Please provide the insurance details and a copy of the insurance card.",
        "There is a billing mismatch":
          "The bill-to information is marked incorrectly.",
      },
    };
  },
  mounted() {
    if (this.width) {
      this.currentWidth = this.width + "%";
    }
  },
  computed: {
    checkReason() {
      const areReasonsEmpty = this.formData.selectedReasons.length === 0;
      const isNotesEmpty = !this.formData.notes.trim();
      const isNotesValid = this.formData.notes.length >= 3;

      return areReasonsEmpty || isNotesEmpty || !isNotesValid;
    },
    displayTitleChanged() {
      return `Reason for change  <span class="red-text">to ${this.title}</span>`;
    },
  },

  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visible = false;
          const data = {
            reasons: this.formData.selectedReasons,
          };
          if (this.formData.notes != "") {
            data.notes = this.formData.notes;
          }
          this.$emit("reasonSubmit", data);
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    updateNotes() {
      const actions = this.formData.selectedReasons
        .map((reason) => this.reasonToActionMap[reason])
        .filter((action) => action);
      this.formData.notes = actions.join("\n");
    },
  },
};
</script>

<style lang="scss">
.red-text {
  color: red;
  text-transform: capitalize;
}
.el-select .el-select__tags > span {
  flex-wrap: wrap !important;
}
.order-reason-change-model .el-select__tags {
  flex-wrap: wrap !important;
}
</style>
