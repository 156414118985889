<template>
  <div id="guarantorForm-view">
    <div class="step-inner-navbar"></div>

    <div class="step-inner-content">
      <div class="vue-form">
        <el-form v-model="guarantorForm">
          <el-row :gutter="15">
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">
                    Relationship
                    <span class="star">*</span>
                  </label>
                  <el-select
                    v-model="guarantorForm.relationship"
                    placeholder="Select Relationship"
                    filterable
                    clearable
                    @clear="clearNameSearch"
                    @change="fillPrimaryDetails()"
                    default-first-option
                    :reserve-keyword="false"
                    
                  >
                    <el-option
                      v-for="(type, index) in relationships"
                      :key="index"
                      :value="type"
                      :label="type"
                    ></el-option>
                  </el-select>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.relationship"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">
                    First Name
                    <span class="star">*</span>
                  </label>
                  <el-input
                    v-model="guarantorForm.first_name"
                    placeholder="Enter First Name"
                  ></el-input>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.first_name"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">Middle Name</label>
                  <el-input
                    v-model="guarantorForm.middle_name"
                    placeholder="Enter Middle Name"
                  ></el-input>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.middle_name"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">
                    Last Name
                    <span class="star">*</span>
                  </label>
                  <el-input
                    v-model="guarantorForm.last_name"
                    placeholder="Enter Last Name"
                  ></el-input>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.last_name"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">Phone Number</label>
                  <el-input
                    v-model="guarantorForm.home_phone"
                    placeholder="Enter Phone Number"
                    maxlength="10"
                    
                  ></el-input>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.home_phone"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">Mobile Number</label>
                  <el-input
                    v-model="guarantorForm.work_phone"
                    placeholder="Enter Mobile Number"
                    maxlength="10"
                    
                  ></el-input>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.work_phone"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text"> Address Line 1 </label>
                  <el-input
                    v-model="guarantorForm.address_line_1"
                    type="input"
                    placeholder="Enter Address Line 1"
                  ></el-input>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.address_line_1"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">Address Line 2</label>
                  <el-input
                    type="input"
                    v-model="guarantorForm.address_line_2"
                    placeholder="Enter Address Line 2"
                  ></el-input>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.address_line_2"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text"> Zip </label>
                  <el-input
                    v-model="guarantorForm.zip"
                    placeholder="Enter Zip"
                    maxlength="5"
                    @input="getStateCity"
                    
                  ></el-input>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.zip"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <state
                    :stateData="guarantorForm"
                    :displayRequired="false"
                  ></state>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.state"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <city
                    :cityData="guarantorForm"
                    v-if="show"
                    :displayRequired="false"
                  ></city>
                  <p class="err">
                    <FormError
                      errorName="billing_info.insurance.guarantor.city"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);
const state = defineAsyncComponent(() =>
  import("@/components/misc/BillingState")
);
const city = defineAsyncComponent(() =>
  import("@/components/misc/BillingCity")
);
import AppHelper from "@/mixins/AppHelper";
export default {
  computed: {
    ...mapGetters("help", ["getAllStates"]),
    ...mapGetters("cases", ["getCaseInformation", "getStateAndCity"]),
  },
  components: {
    FormError,
    state,
    city,
  },
  mixins: [AppHelper],
  mounted() {
    // this.fetchStates();
    this.show = true;
    this.refreshDOB = true;
    if (this.guarantorInsurance) {
      this.guarantorForm = { ...this.guarantorInsurance };
    }
    if (this.$route.params.case_id) {
      this.checkCaseInfo();
      this.patientData = this.getCaseInformation.patient_info;
    }
  },
  props: ["parentRefs", "guarantorInsurance"],
  data() {
    return {
      relationships: caseConstants.BILLING_RELATIONSHIPS,
      show: false,
      refreshDOB: false,
      guarantorForm: {
        relationship: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        home_phone: "",
        work_phone: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        zip: "",
      },
      patientData: {},
    };
  },
  methods: {
    async getStateCity() {
      try {
        let params = {
          zip: this.guarantorForm.zip,
        };
        await this.$store.dispatch("cases/getStateCity", params);
        if (this.getStateAndCity) {
          this.guarantorForm.state = this.getStateAndCity.state;
          this.guarantorForm.city = this.getStateAndCity.city;
        } else {
          if (!this.getStateAndCity && params.zip.length == 5) {
            this.$message({
              message: "No State & City Found",
              type: "warning",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchStates() {
      await this.$store.dispatch("help/fetchAllStates");
    },
    getGuarantorInsuranceFormData() {
      return this.getFormattedValues(this.guarantorForm);
    },

    clearNameSearch() {
      this.guarantorForm = {
        relationship: "",
        plan_type: "",
        payer: "",
        payor: "",
        insurance_code: "",
        gender: "",
        date_of_birth: "",
        policy_plan: "",
        group_or_plan: "",
        group: "",
        patient_policy: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        zip: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        home_phone: "",
        work_phone: "",
      };
    },
    checkCaseInfo() {
      if (
        this.getCaseInformation &&
        this.getCaseInformation.billing_info &&
        this.getCaseInformation.billing_info.insurance &&
        this.getCaseInformation.billing_info.insurance.guarantor
      ) {
        this.guarantorForm =
          this.getCaseInformation.billing_info.insurance.guarantor;
      }
    },

    fillPrimaryDetails() {
      if (this.guarantorForm.relationship === "Self") {
        this.refreshDOB = false;
        let patientInformation =
          this.parentRefs && this.parentRefs.patientInformationForm
            ? this.parentRefs.patientInformationForm.formatPatientInfo()
            : this.patientData;
        this.guarantorForm.first_name = patientInformation.first_name;
        this.guarantorForm.middle_name = patientInformation.middle_name;
        this.guarantorForm.last_name = patientInformation.last_name;
        this.guarantorForm.gender = patientInformation.gender;

        this.guarantorForm.date_of_birth = patientInformation.date_of_birth;

        this.guarantorForm.city = patientInformation.city;
        this.guarantorForm.state = patientInformation.state;
        this.guarantorForm.zip = patientInformation.zip;
        this.guarantorForm.address_line_1 = patientInformation.address_line_1;
        this.guarantorForm.address_line_2 = patientInformation.address_line_2;
        this.guarantorForm.home_phone = patientInformation.home_phone;
        this.guarantorForm.work_phone = patientInformation.work_phone;
        setTimeout(() => {
          this.refreshDOB = true;
        });
      }
    },
  },
};
</script>
