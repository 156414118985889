<template>
  <div class="case-creation__step case-creation__billing-information">
    <div class="case-creation__card edit-view">
      <div class="case-creation__heading">
        <div class="title-with-icon">
          <span class="icon">
            <img
              src="@/assets/images/icons/icon-billing-info.svg"
              alt="icon"
              class="img-fluid"
            />
          </span>
          <h3 class="title">Billing Information</h3>
        </div>

        <div
          class="eligibility-block"
          v-if="
            this.getCaseInformation &&
            this.getCaseInformation.billing_type &&
            this.getCaseInformation.billing_type == 'INSURANCE' &&
            (!isBillingUser || isLabAssistantUser)
          "
        >
          <div
            class="verified"
            @click="openInsurancePopup"
            v-if="
              this.getCaseInformation &&
              this.getCaseInformation.insurance_eligibility_verified
            "
          >
            Insurance Verified

            <el-icon>
              <Finished />
            </el-icon>
          </div>
          <div
            class="not-verified"
            @click="openInsurancePopup"
            v-if="
              this.getCaseInformation &&
              this.getCaseInformation.insurance_eligibility_verified == false &&
              this.getCaseInformation.insurance_eligibility_verified_at
            "
          >
            Insurance Not Verified
            <el-icon><CloseBold /></el-icon>
          </div>
          <div
            class="date-time"
            v-if="
              this.getCaseInformation &&
              this.getCaseInformation.insurance_eligibility_verified_at
            "
          >
            {{
              momentWithTimezone(
                this.getCaseInformation.insurance_eligibility_verified_at,
                "MM-DD-yyyy hh:mm A"
              )
            }}
          </div>
          <div
            v-if="
              this.getCaseInformation.insurance_eligibility_verified == true
            "
          >
            <el-button
              class="eligibility-btn"
              @click="checkInsuracneEligibility"
              :loading="eligibilityLoading"
              :disabled="checkRecheckEligibility"
              >RE-CHECK INSURANCE ELIGIBILITY</el-button
            >
          </div>
          <div
            v-else-if="
              this.getCaseInformation.insurance_eligibility_verified == false &&
              this.getCaseInformation.insurance_eligibility_verified_at
            "
          >
            <el-button
              class="eligibility-btn"
              @click="checkInsuracneEligibility"
              :loading="eligibilityLoading"
              :disabled="checkRecheckEligibility"
              >RE-CHECK INSURANCE ELIGIBILITY</el-button
            >
          </div>
          <div v-else>
            <el-button
              class="eligibility-btn check"
              @click="checkInsuracneEligibility"
              :loading="eligibilityLoading"
              >CHECK INSURANCE ELIGIBILITY</el-button
            >
          </div>
        </div>
        <div class="editBilling" v-if="!this.editForm && !isBillingUser">
          <el-button class="editButton mr-5" @click="goToEditForm">
            <el-icon :size="size" :color="color">
              <Edit />
            </el-icon>
            Edit
          </el-button>
        </div>
      </div>
      <div
        class="case-creation__billing-information__body"
        v-if="this.editForm"
      >
        <div
          class="case-creation__tabs billing-information-tabs"
          v-loading="loading"
        >
          <el-tabs v-model="activeName" @tab-click="updateSelectedTabQuery">
            <el-tab-pane name="first">
              <template #label>
                <span class="inactive-tabs" :class="{ 'active-tabs': hasError }"
                  >primary insurance</span
                >
              </template>
              <PrimaryInsurance
                v-if="activeName === 'first' && showInsuranceDob"
                :primaryInsurance="primaryInsuranceForm"
                :refreshDob="refreshPatientDob"
                :parentRefs="$refs"
                ref="primaryInsuranceForm"
              ></PrimaryInsurance>
            </el-tab-pane>
            <el-tab-pane name="second">
              <template #label>
                <span
                  class="inactive-tabs"
                  :class="{ 'active-tabs': hasSecondaryErrors }"
                  >Secondary Insurance</span
                >
              </template>
              <SecondaryInsurance
                v-if="activeName === 'second' && showInsuranceDob"
                :secondaryInsurance="secondaryInsuranceForm"
                ref="secondaryInsuranceForm"
                :parentRefs="$refs"
                :refreshDob="true"
                :patientInfo="patientDetails"
              ></SecondaryInsurance>
            </el-tab-pane>
            <el-tab-pane name="third">
              <template #label>
                <span
                  class="inactive-tabs"
                  :class="{ 'active-tabs': hasTertiaryErrors }"
                  >Tertiary Insurance</span
                >
              </template>

              <TertiaryInsuranceForm
                v-if="activeName === 'third' && showInsuranceDob"
                :tertiaryInsurance="tertiaryInsuranceForm"
                ref="tertiaryInsuranceForm"
                :parentRefs="$refs"
                :refreshDob="true"
              ></TertiaryInsuranceForm>
            </el-tab-pane>
            <el-tab-pane name="fourth">
              <template #label>
                <span
                  class="inactive-tabs"
                  :class="{ 'active-tabs': hasGuarantorErrors }"
                  >Guarantor</span
                >
              </template>

              <GuarantorInsurance
                v-if="activeName === 'fourth' && showInsuranceDob"
                :guarantorInsurance="guarantorInsuranceForm"
                ref="guarantorInsuranceForm"
                :parentRefs="$refs"
              ></GuarantorInsurance>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="case-creation__actions">
          <ul class="list">
            <li>
              <el-button class="btn cancel-button" @click="saveInsurance"
                >Save</el-button
              >
            </li>
            <li>
              <el-button class="btn save-button mr-20" @click="cancelEdit"
                >Cancel</el-button
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="case-creation__body" v-else>
        <div class="case-creation__tabs billing-information-tabs vue-tabs">
          <el-tabs v-model="activeName">
            <el-tab-pane label="PRIMARY INSURANCE" name="first">
              <div id="primary-insurance" v-if="activeName === 'first'">
                <div class="primary-insurance-view">
                  <div class="step-inner-navbar"></div>

                  <div class="step-inner-content">
                    <div class="vue-form">
                      <el-form>
                        <el-row :gutter="15">
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Relationship</h4>
                              <p
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.relationship
                                "
                              >
                                {{ getBillingInformation.relationship }}
                              </p>
                              <p class="value" v-else>--</p>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Plan Type</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.plan_type
                                "
                              >
                                {{ getBillingInformation.plan_type }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">First Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.first_name
                                "
                              >
                                {{ getBillingInformation.first_name }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Middle Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.middle_name
                                "
                              >
                                {{ getBillingInformation.middle_name }}
                              </span>
                              <span class="value" v-else> --</span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Last Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.last_name
                                "
                              >
                                {{ getBillingInformation.last_name }}
                              </span>
                              <span class="value" v-else> --</span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Gender</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.gender
                                "
                              >
                                {{ getBillingInformation.gender }}
                              </span>
                              <span class="value" v-else> --</span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Date of Birth</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.date_of_birth
                                "
                              >
                                {{
                                  formatDate(
                                    getBillingInformation.date_of_birth
                                  )
                                }}
                              </span>
                              <span class="value" v-else> --</span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.payor
                                "
                              >
                                {{ getBillingInformation.payor }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Carrier Code</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getCaseInformation.billing_info.insurance
                                    .primary_insurance.insurance_carrier_code
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Policy No</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.policy_id
                                "
                              >
                                {{ getBillingInformation.policy_id }}
                              </span>
                              <span class="value" v-else> --</span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Group#</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.group
                                "
                              >
                                {{ getBillingInformation.group }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 1</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.address_line_1
                                "
                              >
                                {{ getBillingInformation.address_line_1 }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 2</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.address_line_2
                                "
                              >
                                {{ getBillingInformation.address_line_2 }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Zip</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.zip
                                "
                              >
                                {{ getBillingInformation.zip }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">State</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.state
                                "
                              >
                                {{ getBillingInformation.state }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">City</h4>
                              <span
                                class="value"
                                v-if="
                                  getBillingInformation &&
                                  getBillingInformation.city
                                "
                              >
                                {{ getBillingInformation.city }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col
                            :xl="3"
                            :lg="4"
                            :md="8"
                            :sm="12"
                            :xs="12"
                            v-if="!isBillingUser"
                          >
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Plan Name</h4>
                              <span class="value" v-if="checkInsurance">
                                {{ checkInsurance.name || "--" }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col
                            :xl="3"
                            :lg="4"
                            :md="8"
                            :sm="12"
                            :xs="12"
                            v-if="!isBillingUser"
                          >
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">
                                Total Individual Deductible Amount
                              </h4>
                              <span class="value" v-if="checkInsurance">
                                {{ checkInsurance.individualDeductible }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col
                            :xl="3"
                            :lg="4"
                            :md="8"
                            :sm="12"
                            :xs="12"
                            v-if="!isBillingUser"
                          >
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">
                                Individual Remaining Deductible Amount
                              </h4>
                              <span class="value" v-if="checkInsurance">
                                {{
                                  checkInsurance.individualDeductibleRemaining ||
                                  "0"
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col
                            :xl="3"
                            :lg="4"
                            :md="8"
                            :sm="12"
                            :xs="12"
                            v-if="!isBillingUser"
                          >
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Effective date</h4>
                              <span class="value" v-if="checkInsurance">
                                {{
                                  checkInsurance.policyStart
                                    ? formatDate(checkInsurance.policyStart)
                                    : "--"
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col
                            :xl="3"
                            :lg="4"
                            :md="8"
                            :sm="12"
                            :xs="12"
                            v-if="!isBillingUser"
                          >
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Termination date</h4>
                              <span class="value" v-if="checkInsurance">
                                {{
                                  checkInsurance.policyEnd
                                    ? formatDate(checkInsurance.policyEnd)
                                    : "--"
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col
                            :xl="3"
                            :lg="4"
                            :md="8"
                            :sm="12"
                            :xs="12"
                            v-if="!isBillingUser"
                          >
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Group Name</h4>
                              <span class="value" v-if="checkInsurance">
                                {{ checkInsurance.groupName || "--" }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col
                            :xl="3"
                            :lg="4"
                            :md="8"
                            :sm="12"
                            :xs="12"
                            v-if="!isBillingUser"
                          >
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Group Number</h4>
                              <span class="value" v-if="checkInsurance">
                                {{ checkInsurance.groupNumber || "--" }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                        </el-row>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="SECONDARY INSURANCE" name="second">
              <div id="secondary-insurance" v-if="activeName === 'second'">
                <div class="secondary-insurance-view">
                  <div class="step-inner-navbar"></div>

                  <div class="step-inner-content">
                    <div class="vue-form">
                      <el-form>
                        <el-row :gutter="15">
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Relationship</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.relationship
                                "
                              >
                                {{ getSecondaryInsurance.relationship }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Plan Type</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.plan_type
                                "
                              >
                                {{ getSecondaryInsurance.plan_type }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">First Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.first_name
                                "
                              >
                                {{ getSecondaryInsurance.first_name }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Middle Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.middle_name
                                "
                              >
                                {{ getSecondaryInsurance.middle_name }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Last Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.last_name
                                "
                              >
                                {{ getSecondaryInsurance.last_name }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Gender</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.gender
                                "
                              >
                                {{ getSecondaryInsurance.gender }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Date of Birth</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.date_of_birth
                                "
                              >
                                {{
                                  formatDate(
                                    getSecondaryInsurance.date_of_birth
                                  )
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.payor
                                "
                              >
                                {{ getSecondaryInsurance.payor }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Carrier Code</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.insurance_carrier_code
                                "
                              >
                                {{
                                  getSecondaryInsurance.insurance_carrier_code
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Policy No</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.policy_id
                                "
                              >
                                {{ getSecondaryInsurance.policy_id }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Group#</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.group
                                "
                              >
                                {{ getSecondaryInsurance.group }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 1</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.address_line_1
                                "
                              >
                                {{ getSecondaryInsurance.address_line_1 }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 2</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.address_line_2
                                "
                              >
                                {{ getSecondaryInsurance.address_line_2 }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Zip</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.zip
                                "
                              >
                                {{ getSecondaryInsurance.zip }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">State</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.state
                                "
                              >
                                {{ getSecondaryInsurance.state }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">City</h4>
                              <span
                                class="value"
                                v-if="
                                  getSecondaryInsurance &&
                                  getSecondaryInsurance.city
                                "
                              >
                                {{ getSecondaryInsurance.city }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                        </el-row>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="TERTIARY INSURANCE" name="third">
              <div id="tertiary-insurance" v-if="activeName === 'third'">
                <div class="tertiary-insurance-view">
                  <div class="step-inner-navbar"></div>

                  <div class="step-inner-content">
                    <div class="vue-form">
                      <el-form>
                        <el-row :gutter="15">
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Relationship</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.relationship
                                "
                              >
                                {{ getTertiaryInsurance.relationship }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Plan Type</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.plan_type
                                "
                              >
                                {{ getTertiaryInsurance.plan_type }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">First Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.first_name
                                "
                              >
                                {{ getTertiaryInsurance.first_name }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Middle Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.middle_name
                                "
                              >
                                {{ getTertiaryInsurance.middle_name }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Last Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.last_name
                                "
                              >
                                {{ getTertiaryInsurance.last_name }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Gender</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.gender
                                "
                              >
                                {{ getTertiaryInsurance.gender }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Date of Birth</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.date_of_birth
                                "
                              >
                                {{
                                  formatDate(getTertiaryInsurance.date_of_birth)
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Name</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.payor
                                "
                              >
                                {{ getTertiaryInsurance.payor }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Carrier Code</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.insurance_carrier_code
                                "
                              >
                                {{
                                  getTertiaryInsurance.insurance_carrier_code
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Policy No</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.policy_id
                                "
                              >
                                {{ getTertiaryInsurance.policy_id }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Group#</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.group
                                "
                              >
                                {{ getTertiaryInsurance.group }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 1</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.address_line_1
                                "
                              >
                                {{ getTertiaryInsurance.address_line_1 }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 2</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.address_line_2
                                "
                              >
                                {{ getTertiaryInsurance.address_line_2 }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Zip</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.zip
                                "
                              >
                                {{ getTertiaryInsurance.zip }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">State</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.state
                                "
                              >
                                {{ getTertiaryInsurance.state }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">City</h4>
                              <span
                                class="value"
                                v-if="
                                  getTertiaryInsurance &&
                                  getTertiaryInsurance.city
                                "
                              >
                                {{ getTertiaryInsurance.city }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                        </el-row>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="GUARANTOR INSURANCE" name="fourth">
              <div id="guarantorForm-view" v-if="activeName === 'fourth'">
                <div class="step-inner-navbar"></div>

                <div class="step-inner-content">
                  <div class="vue-form">
                    <el-form>
                      <el-row :gutter="15">
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Relationship</h4>
                            <span
                              class="value"
                              v-if="getGuarantor && getGuarantor.relationship"
                            >
                              {{ getGuarantor.relationship }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">First Name</h4>
                            <span
                              class="value"
                              v-if="getGuarantor && getGuarantor.first_name"
                            >
                              {{ getGuarantor.first_name }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Middle Name</h4>
                            <span
                              class="value"
                              v-if="getGuarantor && getGuarantor.middle_name"
                            >
                              {{ getGuarantor.middle_name }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Last Name</h4>
                            <span
                              class="value"
                              v-if="getGuarantor && getGuarantor.last_name"
                            >
                              {{ getGuarantor.last_name }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <!-- <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Gender</h4>
                            <span class="value" v-if="getGuarantor && getGuarantor.gender">
                              {{
                                getGuarantor.gender
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>

                        </el-col> -->
                        <el-col :xl="6" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Address Line 1</h4>
                            <span
                              class="value"
                              v-if="getGuarantor && getGuarantor.address_line_1"
                            >
                              {{ getGuarantor.address_line_1 }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Address Line 2</h4>
                            <span
                              class="value"
                              v-if="getGuarantor && getGuarantor.address_line_2"
                            >
                              {{ getGuarantor.address_line_2 }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Zip</h4>
                            <span
                              class="value"
                              v-if="getGuarantor && getGuarantor.zip"
                            >
                              {{ getGuarantor.zip }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">State</h4>
                            <span
                              class="value"
                              v-if="getGuarantor && getGuarantor.state"
                            >
                              {{ getGuarantor.state }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">City</h4>
                            <span
                              class="value"
                              v-if="getGuarantor && getGuarantor.city"
                            >
                              {{ getGuarantor.city }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
  <div class="default-modal" v-if="insurancePopup">
    <el-dialog
      v-model="insurancePopup"
      v-loading="showInsuranceLoading"
      top="5vh"
      width="70%"
    >
      <div class="export-dialog-header">
        <div class="export-header-part">
          <h3>Insurance Details</h3>
        </div>
        <el-button
          size="medium"
          class="el-white"
          type="info"
          plain
          @click="insurancePopup = false"
        >
          <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
        /></el-button>
      </div>
      <div id="insurance-details">
        <div class="insurance-details-block">
          <ul>
            <template
              v-for="(value, key) in insuranceEligibilityDetails"
              :key="key"
            >
              <li class="insurance-details-list">
                <div class="insurance-block">
                  <p class="insurance-label">{{ key }}:</p>
                  <span v-if="isObject(value) && key !== 'foundCoverages'">
                    <ul class="insurance-sub-order">
                      <li
                        class="insurance-sub-list"
                        v-for="(subValue, subKey) in value"
                        :key="subKey"
                      >
                        <template
                          v-if="
                            subKey !== 'rawResponse' &&
                            subKey !== 'additionalIdentifications' &&
                            subKey !== 'patient' &&
                            subKey !== 'rejectionCodes'
                          "
                        >
                          <div
                            v-if="isObject(subValue)"
                            class="insurance-sub-details"
                          >
                            <strong>{{ subKey }}:</strong>
                            <ul>
                              <li
                                v-for="(nestedValue, nestedKey) in subValue"
                                :key="nestedKey"
                              >
                                <strong>{{ nestedKey }}:</strong>
                                <span v-if="isDate(nestedValue)">
                                  {{ formatDateBilling(nestedValue) }}
                                </span>
                                <span v-else>{{ nestedValue }}</span>
                              </li>
                            </ul>
                          </div>
                          <div v-else class="insurance-sub-details">
                            <strong>{{ subKey }}:</strong>
                            <span v-if="isDate(subValue)">
                              {{ formatDateBilling(subValue) }}
                            </span>
                            <span v-else>{{ subValue }}</span>
                          </div>
                        </template>
                      </li>
                    </ul>
                  </span>
                  <span v-if="key === 'foundCoverages' && Array.isArray(value)">
                    <ul class="insurance-sub-order">
                      <li
                        v-for="(item, index) in value"
                        :key="index"
                        class="insurance-sub-list"
                      >
                        <div
                          class="main-sub-block"
                          v-for="(subValue, subKey) in item"
                          :key="subKey"
                        >
                          <template
                            v-if="
                              subKey !== 'rawResponse' &&
                              subKey !== 'additionalIdentifications' &&
                              subKey !== 'patient' &&
                              subKey !== 'rejectionCodes'
                            "
                          >
                            <div class="insurance-sub-block">
                              <p class="insurance-label">{{ subKey }}:</p>
                              <span v-if="isObject(subValue)">
                                <ul class="Patient-details">
                                  <li
                                    v-for="(nestedValue, nestedKey) in subValue"
                                    :key="nestedKey"
                                  >
                                    <p class="insurance-label">
                                      {{ nestedKey }}:
                                    </p>
                                    <span v-if="isDate(nestedValue)">
                                      {{ formatDateBilling(nestedValue) }}
                                    </span>
                                    <span v-else>{{ nestedValue }}</span>
                                  </li>
                                </ul>
                              </span>
                              <span v-else>
                                <span v-if="isDate(subValue)">
                                  {{ formatDateBilling(subValue) }}
                                </span>
                                <span v-else>{{ subValue }}</span>
                              </span>
                            </div>
                          </template>
                        </div>
                      </li>
                      <li v-for="addi in value" :key="addi">
                        <div
                          class="main-sub-block"
                          v-for="add in addi.additionalIdentifications"
                          :key="add"
                        >
                          <div class="insurance-sub-block m-10">
                            <p class="insurance-label">
                              Additional Identifications:
                            </p>
                            <div class="sub-details-block">
                              <ul class="Patient-details">
                                <li>
                                  <div class="sub-details">
                                    <p class="insurance-label">
                                      identificationQualifier
                                    </p>
                                    <span>
                                      {{ add.identificationQualifier }}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div class="sub-details">
                                    <p class="insurance-label">identifier</p>
                                    <span>
                                      {{ add.identifier }}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div class="sub-details">
                                    <p class="insurance-label">
                                      identifierDescription
                                    </p>
                                    <span>
                                      {{ add.identifierDescription }}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li v-for="address in value" :key="address">
                        <div class="main-sub-block">
                          <div class="insurance-sub-block m-10">
                            <p class="insurance-label">Patient Info:</p>
                            <div class="sub-details-block">
                              <ul
                                class="Patient-details"
                                v-if="address && address.patient"
                              >
                                <li
                                  v-for="(value, key) in filteredPatient(
                                    address.patient
                                  )"
                                  :key="value"
                                >
                                  <div class="sub-details">
                                    <p class="insurance-label">{{ key }}</p>
                                    <span>
                                      <!-- Check if the value is a date, if so, apply the formatDate function -->

                                      <template
                                        v-if="
                                          key === 'dateOfBirth' && isDate(value)
                                        "
                                      >
                                        {{ formatDateBilling(value) }}
                                      </template>
                                      <!-- If it's not a date, just display the value as it is -->
                                      <template v-else>
                                        {{ value }}
                                      </template>
                                    </span>
                                  </div>
                                </li>

                                <li
                                  v-for="addr in address.patient.addresses"
                                  :key="addr.addressLine1"
                                >
                                  <div class="sub-details">
                                    <p class="insurance-label">
                                      Address Line 1
                                    </p>
                                    <span>
                                      {{ addr.addressLine1 }}
                                    </span>
                                  </div>
                                </li>
                                <li
                                  v-for="addr in address.patient.addresses"
                                  :key="addr.addressLine1"
                                >
                                  <div class="sub-details">
                                    <p class="insurance-label">
                                      Address Line 2:
                                    </p>
                                    <div style="padding: 10px">
                                      <p>{{ addr.addressLine2 }}</p>
                                      <p>City: {{ addr.city }}</p>
                                      <p>State: {{ addr.state }}</p>
                                      <p>Zip: {{ addr.zip }}</p>
                                      <p v-if="addr.zipExtension">
                                        Zip Extension: {{ addr.zipExtension }}
                                      </p>
                                      <span v-if="addr.county"
                                        >County: {{ addr.county }}</span
                                      >
                                      <span v-if="addr.countyCode"
                                        >County Code:
                                        {{ addr.countyCode }}</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  v-for="addr in address.patient.addresses"
                                  :key="addr.addressLine1"
                                >
                                  <div class="sub-details">
                                    <p class="insurance-label">Reported On</p>
                                  </div>
                                  <span v-if="addr.reportedOn">
                                    {{ addr.reportedOn }}</span
                                  >
                                </li>
                                <li
                                  v-for="addr in address.patient.addresses"
                                  :key="addr.addressLine1"
                                >
                                  <div class="sub-details">
                                    <p class="insurance-label">
                                      Current Address:
                                    </p>
                                  </div>

                                  <span> {{ addr.currentAddress }}</span>
                                </li>
                                <li
                                  v-for="addr in address.patient.addresses"
                                  :key="addr.addressLine1"
                                >
                                  <div class="sub-details">
                                    <p class="insurance-label">
                                      CASS Corrected
                                    </p>
                                  </div>
                                  <span>
                                    {{ addr.cassCorrected }}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li v-for="addi in value" :key="addi">
                        <div
                          class="main-sub-block"
                          v-if="
                            addi &&
                            addi.rejectionCodes &&
                            addi.rejectionCodes.length
                          "
                        >
                          <div class="insurance-sub-block m-10">
                            <p class="insurance-label">Rejection Codes:</p>
                            <div class="sub-details-block">
                              <ul
                                class="Patient-details"
                                v-for="add in addi.rejectionCodes"
                                :key="add"
                              >
                                <li>
                                  <div class="sub-details">
                                    <p class="insurance-label">Code</p>
                                    <span>
                                      {{ add.code }}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div class="sub-details">
                                    <p class="insurance-label">Location</p>
                                    <span>
                                      {{ add.location }}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </span>
                  <span v-else>
                    <span v-if="isDate(value)">
                      {{ formatDateBilling(value) }}
                    </span>
                    <span v-else>{{ value }}</span>
                  </span>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </el-dialog>
  </div>
  <ResonForChange
    v-if="takeReasonDialog"
    v-on:close="takeReasonDialog = false"
    v-on:reasonSubmit="updateCase"
  ></ResonForChange>
  <BillingValidation
    v-if="icdErrorDialog"
    :validationErrors="billingErrors"
    v-on:close="icdErrorDialog = false"
    :caseCreation="true"
  ></BillingValidation>
</template>
<script>
import { mapGetters } from "vuex";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import moment from "moment";
import PrimaryInsurance from "@/components/cases/billingInfo/forms/PrimaryInsurance";
import SecondaryInsurance from "@/components/cases/billingInfo/forms/SecondaryInsurance";
import TertiaryInsuranceForm from "@/components/cases/billingInfo/forms/TertiaryInsurance";
import GuarantorInsurance from "@/components/cases/billingInfo/forms/GuarantorInsurance";
import ResonForChange from "@/components/misc/ResonForChange";
import InsuranceHelper from "@/mixins/InsuranceHelper";
import BillingValidation from "@/components/cases/BillingValidation";
export default {
  mixins: [UserPermissionsHelper, InsuranceHelper],
  components: {
    PrimaryInsurance,
    SecondaryInsurance,
    TertiaryInsuranceForm,
    GuarantorInsurance,
    ResonForChange,
    BillingValidation,
  },
  data() {
    return {
      activeName: "first",
      caseId: "",
      loading: false,
      takeReasonDialog: false,
      insurancePopup: false,
      insuranceEligibilityDetails: {},
      showInsuranceLoading: false,
      eligibilityLoading: false,
      foundCoverages: [],
      editForm: false,
      showInsuranceDob: false,
      primaryInsuranceForm: {},
      secondaryInsuranceForm: {},
      tertiaryInsuranceForm: {},
      guarantorInsuranceForm: {},
      panelsTestsData: {
        selectedTestIds: [],
        selectedCoriellPanels: [],
      },
      patientDetails: "",
      icdErrorDialog: false,
      billingErrors: [],
    };
  },
  computed: {
    ...mapGetters("cases", [
      "getCaseInformation",
      "getDeleteCaseBillingInfoStatus",
      "getInsuranceEligibility",
      "getInsuranceEligibilityDetails",
      "getCaseInformationSaveStatus",
      "getCaseInformationValidationErrorsCode",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    isCaseCompleted() {
      if (!this.getCaseInformation) {
        return false;
      }
      return this.getCaseInformation.status === "COMPLETE";
    },
    getBillingInformation() {
      if (
        this.getCaseInformation &&
        Object.keys(this.getCaseInformation).length > 0 &&
        this.getCaseInformation.billing_info &&
        this.getCaseInformation.billing_info.insurance
      ) {
        return this.getCaseInformation.billing_info.insurance.primary_insurance;
      }
      return null;
    },
    getSecondaryInsurance() {
      if (
        this.getCaseInformation &&
        Object.keys(this.getCaseInformation).length > 0 &&
        this.getCaseInformation.billing_info &&
        this.getCaseInformation.billing_info.insurance
      ) {
        return this.getCaseInformation.billing_info.insurance
          .secondary_insurance;
      }
      return null;
    },
    getTertiaryInsurance() {
      if (
        this.getCaseInformation &&
        Object.keys(this.getCaseInformation).length > 0 &&
        this.getCaseInformation.billing_info &&
        this.getCaseInformation.billing_info.insurance
      ) {
        return this.getCaseInformation.billing_info.insurance
          .tertiary_insurance;
      }
      return null;
    },
    getGuarantor() {
      if (
        this.getCaseInformation &&
        Object.keys(this.getCaseInformation).length > 0 &&
        this.getCaseInformation.billing_info &&
        this.getCaseInformation.billing_info.insurance
      ) {
        return this.getCaseInformation.billing_info.insurance.guarantor;
      }
      return null;
    },
    checkInsurance() {
      if (
        this.getCaseInformation &&
        this.getCaseInformation.insurance_eligibility_verified &&
        this.insuranceEligibilityDetails &&
        this.insuranceEligibilityDetails.foundCoverages &&
        this.insuranceEligibilityDetails.foundCoverages[0]
      ) {
        return this.insuranceEligibilityDetails.foundCoverages[0].summary;
      }
      return null;
    },
    checkRecheckEligibility() {
      if (
        this.getCaseInformation &&
        (this.getCaseInformation.insurance_eligibility_verified ||
          this.getCaseInformation.insurance_eligibility_verified_at) &&
        this.getCaseInformation.allow_recheck_insurance
      ) {
        return false;
      }
      return true;
    },
    hasError() {
      if (this.getErrors && Object.keys(this.getErrors).length) {
        let errorsValues = Object.keys(this.getErrors);
        for (let i = 0; i < errorsValues.length; i++) {
          if (
            errorsValues[i].indexOf(
              "billing_info.insurance.primary_insurance"
            ) !== -1
          ) {
            return true;
          }
        }
      }
      return false;
    },
    hasSecondaryErrors() {
      if (this.getErrors && Object.keys(this.getErrors).length) {
        let errorsValues = Object.keys(this.getErrors);
        for (let i = 0; i < errorsValues.length; i++) {
          if (
            errorsValues[i].indexOf(
              "billing_info.insurance.secondary_insurance"
            ) !== -1
          ) {
            return true;
          }
        }
      }
      return false;
    },
    hasTertiaryErrors() {
      if (this.getErrors && Object.keys(this.getErrors).length) {
        let errorsValues = Object.keys(this.getErrors);
        for (let i = 0; i < errorsValues.length; i++) {
          if (
            errorsValues[i].indexOf(
              "billing_info.insurance.tertiary_insurance"
            ) !== -1
          ) {
            return true;
          }
        }
      }
      return false;
    },
    hasGuarantorErrors() {
      if (this.getErrors && Object.keys(this.getErrors).length) {
        let errorsValues = Object.keys(this.getErrors);
        for (let i = 0; i < errorsValues.length; i++) {
          if (
            errorsValues[i].indexOf("billing_info.insurance.guarantor") !== -1
          ) {
            return true;
          }
        }
      }
      return false;
    },
  },
  mounted() {
    this.showInsuracneEligibilityDetails();
  },
  methods: {
    filteredPatient(patient) {
      const filtered = {};
      for (const key in patient) {
        if (key !== "addresses") {
          filtered[key] = patient[key];
        }
      }
      return filtered;
    },
    isObject(value) {
      return (
        value !== null && typeof value === "object" && !Array.isArray(value)
      );
    },
    isDate(value) {
      return moment(value, moment.ISO_8601, true).isValid();
    },
    formatDateBilling(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    goToEditForm() {
      // this.$router.push({
      //   name: "Case-Information-Update",
      //   params: { case_id: this.$route.params.case_id },
      //   query: this.$route.query,
      // });
      this.showInsuranceDob = true;

      this.editForm = true;
      this.fetchCaseInformation();
    },
    cancelEdit() {
      this.editForm = false;
      this.showInsuranceDob = false;
    },
    async fetchCaseInformation() {
      try {
        this.caseId = this.$route.params.case_id;

        this.loading = true;
        await this.$store.dispatch("cases/fetchCaseInformation", this.caseId);

        if (
          this.getCaseInformation &&
          this.getCaseInformation.billing_info &&
          this.getCaseInformation.billing_info.insurance &&
          this.getCaseInformation.billing_info.insurance.primary_insurance
        ) {
          this.primaryInsuranceForm = this.setInsuranceDataFromBackendData(
            this.getCaseInformation.billing_info.insurance.primary_insurance
          );
        }
        if (
          this.getCaseInformation &&
          this.getCaseInformation.billing_info &&
          this.getCaseInformation.billing_info.insurance &&
          this.getCaseInformation.billing_info.insurance.secondary_insurance
        ) {
          this.secondaryInsuranceForm = this.setInsuranceDataFromBackendData(
            this.getCaseInformation.billing_info.insurance.secondary_insurance
          );
        }
        if (
          this.getCaseInformation &&
          this.getCaseInformation.billing_info &&
          this.getCaseInformation.billing_info.insurance &&
          this.getCaseInformation.billing_info.insurance.tertiary_insurance
        ) {
          this.tertiaryInsuranceForm = this.setInsuranceDataFromBackendData(
            this.getCaseInformation.billing_info.insurance.tertiary_insurance
          );
        }
        if (
          this.getCaseInformation &&
          this.getCaseInformation.billing_info &&
          this.getCaseInformation.billing_info.insurance &&
          this.getCaseInformation.billing_info.insurance.guarantor
        ) {
          this.guarantorInsuranceForm =
            this.getCaseInformation.billing_info.insurance.guarantor;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async checkInsuracneEligibility() {
      this.caseId = this.$route.params.case_id;
      try {
        this.eligibilityLoading = true;
        await this.$store.dispatch(
          "cases/checkInsuranceEligibility",
          this.caseId
        );
        if (this.getInsuranceEligibility) {
          successNotification("Insurance verified successfully");
          this.fetchCaseInformation();
          setTimeout(() => {
            this.insurancePopup = true;
          }, 1000);

          this.insuranceEligibilityDetails = {
            ...this.getInsuranceEligibility.data,
          };
          this.foundCoverages = this.insuranceEligibilityDetails.foundCoverages;
        } else {
          errorNotification(
            this.getErrors.critical_error || "Error While Insurance verifying"
          );
        }
        this.eligibilityLoading = false;
      } catch (err) {
        this.eligibilityLoading = false;
        console.log(err);
      }
    },
    openInsurancePopup() {
      if (
        this.getInsuranceEligibilityDetails &&
        this.getInsuranceEligibilityDetails.data
      ) {
        this.insurancePopup = true;
      }
    },
    async showInsuracneEligibilityDetails() {
      this.caseId = this.$route.params.case_id;
      try {
        this.showInsuranceLoading = true;
        await this.$store.dispatch(
          "cases/fetchInsuranceEligibleDetails",
          this.caseId
        );
        if (
          this.getInsuranceEligibilityDetails &&
          this.getInsuranceEligibilityDetails.data
        ) {
          this.insuranceEligibilityDetails = {
            ...this.getInsuranceEligibilityDetails.data.insurance_eligibility_verified_details
          };
          this.foundCoverages = this.insuranceEligibilityDetails.foundCoverages;
        } else {
          if (
            !this.getInsuranceEligibilityDetails &&
            this.getInsuranceEligibilityDetails.data
          )
            errorNotification("Error in clearing Billing Information details");
        }
        this.showInsuranceLoading = false;
      } catch (err) {
        this.showInsuranceLoading = false;
        console.log(err);
      }
    },
    prepareRequestBody() {
      return {
        case_types: this.getCaseInformation.case_types || [],
        lab: this.getCaseInformation.lab
          ? this.getCaseInformation.lab._id
          : null,
        collection_date: this.getCaseInformation.collection_date,
        received_date: this.getCaseInformation.received_date,
        hospital: this.getCaseInformation.hospital
          ? this.getCaseInformation.hospital._id
          : null,
        icd_10_codes: this.getCaseInformation.icd_10_codes || [],
        ordering_physician: this.getCaseInformation.ordering_physician._id,
        referring_physician:
          this.getCaseInformation.referring_physician &&
          this.getCaseInformation.referring_physician._id
            ? this.getCaseInformation.referring_physician._id
            : null,
        hospital_branch: this.getCaseInformation.hospital_branch
          ? this.getCaseInformation.hospital_branch._id
          : null,
        billing_type: this.getCaseInformation.billing_type,
        specimen_collector: this.getCaseInformation.specimen_collector,
        additional_fax: this.getCaseInformation.additional_fax || [],
        order_id: this.getCaseInformation.order_id,
        sample_types: this.getCaseInformation.sample_types.map(
          (sampleType) => ({
            sample_types: sampleType.sample_types,

            case_type: sampleType.case_type,
          })
        ),
        additional_fax_requisite:
          this.getCaseInformation.additional_fax_requisite,

        patient_info: {
          first_name: this.getCaseInformation.patient_info.first_name,
          last_name: this.getCaseInformation.patient_info.last_name,
          gender: this.getCaseInformation.patient_info.gender,
          date_of_birth: this.getCaseInformation.patient_info.date_of_birth,
          home_phone: this.getCaseInformation.patient_info.home_phone,
          address_line_1: this.getCaseInformation.patient_info.address_line_1,
          address_line_2: this.getCaseInformation.patient_info.address_line_2,
          city: this.getCaseInformation.patient_info.city,
          state: this.getCaseInformation.patient_info.state,
          zip: this.getCaseInformation.patient_info.zip,
          hospital: this.getCaseInformation.patient_info.hospital,
        },
        tests_info:
          this.getCaseInformation && this.getCaseInformation.tests_info
            ? this.getCaseInformation.tests_info.map((el) => el._id)
            : [],
        coriell_test_panels:
          this.getCaseInformation.coriell_test_panels &&
          this.getCaseInformation.coriell_test_panels.length
            ? this.getCaseInformation.coriell_test_panels
            : [],
        medication: this.getCaseInformation.medication || [],
        cpt_codes: this.getCaseInformation.cpt_codes || [],
        toxicology_ereq_form:
          this.getCaseInformation.toxicology_ereq_form || [],
      };
    },
    async saveInsurance() {
      this.takeReasonDialog = true;
    },
    async updateCase(reason) {
      this.updateNewCase(reason);
    },
    parseInsuranceForm(insuranceData) {
      let params = { ...insuranceData };

      // filtering the object
      // removing non entered values
      Object.keys(params).forEach((keyValue) => {
        if (!params[keyValue]) {
          delete params[keyValue];
        }
      });

      return params;
    },
    isObjectUpdated(original, updated) {
      const keys = Object.keys(original);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (original[key] !== updated[key]) {
          return true; // If any value is updated, return true
        }
      }

      return false; // If no value is updated, return false
    },
    formatDOB(dateString) {
      // Extracting the date part from the ISO date string
      return dateString.split("T")[0];
    },
    async updateNewCase(reason) {
      try {
        this.loading = true;
        let params = this.prepareRequestBody();
        const caseId = this.$route.params.case_id;
        let insurance = {};

        let primaryInsuranceFormDetails = this.$refs["primaryInsuranceForm"];
        if (primaryInsuranceFormDetails) {
          let primaryInsuranceFormData = this.parseInsuranceForm(
            primaryInsuranceFormDetails.getPrimaryInsuranceFormData()
          );

          if (Object.keys(primaryInsuranceFormData).length) {
            insurance.primary_insurance = primaryInsuranceFormData;
          }
          if (this.$route.params.case_id) {
            const newObj = { ...this.primaryInsuranceForm };
            if (
              newObj.hasOwnProperty("date_of_birth") &&
              newObj.date_of_birth !== ""
            ) {
              newObj.date_of_birth = this.formatDOB(
                this.primaryInsuranceForm.date_of_birth
              );
            }

            const isUpdated = this.isObjectUpdated(
              newObj,
              primaryInsuranceFormData
            );
            if (isUpdated) {
              params.allow_recheck_insurance = true;
            }
          }
        } else {
          if (this.primaryInsuranceForm) {
            if (Object.keys(this.primaryInsuranceForm).length) {
              insurance.primary_insurance = this.primaryInsuranceForm;
            }
          }
        }

        let secondaryInsuranceFormDetails =
          this.$refs["secondaryInsuranceForm"];
        if (secondaryInsuranceFormDetails) {
          let secondaryInsuranceFormData = this.parseInsuranceForm(
            secondaryInsuranceFormDetails.getSecondaryInsuranceFormData()
          );

          if (Object.keys(secondaryInsuranceFormData).length) {
            insurance.secondary_insurance = secondaryInsuranceFormData;
          }
        } else if (this.secondaryInsuranceForm) {
          if (Object.keys(this.secondaryInsuranceForm).length) {
            insurance.secondary_insurance = this.secondaryInsuranceForm;
          }
        }

        let tertiaryInsuranceFormDetails = this.$refs["tertiaryInsuranceForm"];
        if (tertiaryInsuranceFormDetails) {
          let tertiaryInsuranceFormData = this.parseInsuranceForm(
            tertiaryInsuranceFormDetails.getTertiaryInsuranceFormData()
          );

          if (Object.keys(tertiaryInsuranceFormData).length) {
            insurance.tertiary_insurance = tertiaryInsuranceFormData;
          }
        } else if (this.tertiaryInsuranceForm) {
          if (Object.keys(this.tertiaryInsuranceForm).length) {
            insurance.tertiary_insurance = this.tertiaryInsuranceForm;
          }
        }

        let guarantorInsuranceFormDetails =
          this.$refs["guarantorInsuranceForm"];
        if (guarantorInsuranceFormDetails) {
          let guarantorInsuranceFormData = this.parseInsuranceForm(
            guarantorInsuranceFormDetails.getGuarantorInsuranceFormData()
          );

          if (Object.keys(guarantorInsuranceFormData).length) {
            insurance.guarantor = guarantorInsuranceFormData;
          }
        } else if (this.guarantorInsuranceForm) {
          if (Object.keys(this.guarantorInsuranceForm).length) {
            insurance.guarantor = this.guarantorInsuranceForm;
          }
        }
        //  &&
        //   this.caseInformation.billing_type == "INSURANCE"
        if (Object.keys(insurance).length > 0) {
          params.billing_info = { insurance: insurance };
        }
        params.case_id = caseId;
        params.data_update_reason = reason;
        await this.$store.dispatch("cases/updateCase", params);

        if (this.getCaseInformationSaveStatus) {
          successNotification("Case Updated Successfully");
          window.location.reload();
        } else if (
          this.getCaseInformationValidationErrorsCode.error_code ===
          "BILLING_VALIDATION_ERRORS"
        ) {
          this.icdErrorDialog = true;
          this.billingErrors =
            this.getCaseInformationValidationErrorsCode.errors;
        } else if (this.getErrors) {
          this.scrollToFirstError();
        } else {
          if (!this.getErrors || this.getError) {
            errorNotification("Error while updating Case");
          }
        }
        this.loading = false;
        this.takeReasonDialog = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    updateSelectedTabQuery() {
      if (this.getCaseInformation && this.getCaseInformation.patient_info) {
        this.patientDetails = this.getCaseInformation.patient_info;
      }
      const primaryInfo = this.$refs["primaryInsuranceForm"];
      if (primaryInfo) {
        this.primaryInsuranceForm = this.parseInsuranceForm(
          primaryInfo.getPrimaryInsuranceFormData()
        );
      }
      const secondaryInfo = this.$refs["secondaryInsuranceForm"];
      if (secondaryInfo) {
        this.secondaryInsuranceForm = this.parseInsuranceForm(
          secondaryInfo.getSecondaryInsuranceFormData()
        );
      }
      const teritaryInfo = this.$refs["tertiaryInsuranceForm"];
      if (teritaryInfo) {
        this.tertiaryInsuranceForm = this.parseInsuranceForm(
          teritaryInfo.getTertiaryInsuranceFormData()
        );
      }
      const gaurantorInfo = this.$refs["guarantorInsuranceForm"];
      if (gaurantorInfo) {
        this.guarantorInsuranceForm = this.parseInsuranceForm(
          gaurantorInfo.getGuarantorInsuranceFormData()
        );
      }
    },
  },
};
</script>

<style lang="scss">
.case-creation__billing-information {
  .case-creation__body {
    padding: 0;
  }
  .el-tabs__content {
    padding: 0 20px;
  }
  .el-tabs__nav-scroll {
    padding: 0 20px;
  }
  .el-tabs__nav-wrap::after {
    height: 1px;
  }
  .right-align {
    display: flex;
  }
  .eligibility-block {
    width: 60%;
    display: flex;
    align-items: center;
    .verified {
      color: #4bb543;
      border: 0.6px solid #4bb543;
      background-color: #ffffff;
      padding: 1px 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-weight: 500;
      border-radius: 5px;
      min-height: 25px;
      cursor: pointer;
      .el-icon {
        padding-left: 3px;
      }
    }
    .not-verified {
      color: #f24a55;
      border: 0.6px solid #f24a55;
      background-color: #ffffff;
      padding: 1px 10px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      border-radius: 5px;
      min-height: 25px;
      cursor: pointer;
      .el-icon {
        padding-left: 3px;
      }
    }

    .date-time {
      padding: 0 15px;
      font-size: 14px;
      font-weight: 500;
    }
    .eligibility-btn {
      margin-left: 20px;
      background: #f24a55;
      min-height: 25px;
      border: none;
      padding: 5px 20px;
      color: #fff;
      &:hover,
      &:active {
        color: #fff;
        background: #f24a55;
      }
      &.check {
        background: #273480;
        color: #ffffff;
      }
    }
  }
  .delete-billing-info {
    margin-right: 20px;
    padding: 5px 0;

    button {
      background: #f24a55;
      min-height: 25px;
      border: none;

      padding: 5px 20px;
    }
  }
  .editBilling {
    button {
      display: flex;
      align-items: center;
      min-height: 30px;
      cursor: pointer;
      background: none;
      border: none;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      border: 1px solid #f24a55;
      background: #f24a55;
      border-radius: 4px;
      padding: 0px 10px;
    }
  }
}
.verified-table {
  .el-table__body-wrapper {
    width: 100%;
    height: 530px;
    overflow: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      height: 12px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: #b8c4cc;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .el-table {
    background: transparent !important;
    table-layout: fixed;
    width: 100%;
    overflow: initial;
    table {
      thead {
        tr {
          border: 0.633951px solid #e0e0e0;

          th {
            &.el-table__cell {
              padding: 0;
              border-right: none;
              border-left: none;
              background: #005475;
            }
            &.el-table_1_column_1_column_1,
            &.el-table_1_column_1_column_2,
            &.el-table_1_column_1_column_3,
            &.el-table_1_column_1_column_4 {
              background: #005475;
              font-weight: 500;
              height: 32px;
            }
            &.el-table_1_column_5_column_6 {
              border-bottom: none;
            }

            .cell {
              color: #ffffff;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: 0.01em;
              padding: 6px 10px !important;
              text-transform: uppercase;
            }
          }
        }
      }
      tbody {
        tr {
          border: 0.633951px solid #e0e0e0;

          td {
            &.el-table__cell {
              padding: 0;
              border: 0.633951px solid #e0e0e0;
            }

            .cell {
              font-size: clamp(13px, 0.8vw, 1.875rem);
              font-weight: 400;
              color: #000000;
              letter-spacing: 0.5008px;

              padding: 3px 10px;
              white-space: nowrap;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  .el-table--border th.el-table__cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-right: 1px solid rgba(0, 0, 0, 0.15);
  }

  .el-table--border::after,
  .el-table--group::after {
    display: none;
  }
}
.archive-popup {
  &.height-400 {
    .el-dialog {
      height: 615px;
    }
  }
}
#insurance-details {
  border: 1.5px solid #e7e7f7;
  border-radius: 10px;
  margin: 15px;
  padding: 5px;
  .insurance-details-block {
    height: 500px;
    overflow: auto;
    padding: 5px 15px;
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      .insurance-details-list {
        border: 1.5px solid #e7e7f7;
        margin: 5px 0;
        overflow: hidden;
        border-radius: 10px;

        .insurance-block {
          display: grid;
          // grid-template-columns: 1.5fr 3fr;
          align-items: baseline;
          column-gap: 20px;
          font-weight: 500;
          color: #000000;
          span:nth-child(2) {
            padding: 5px 15px;
          }
        }
        .insurance-label {
          color: #005475;
          font-weight: 600;
          font-size: 15px;
          text-transform: capitalize;
          background: #f3f3f3;
          padding: 5px 15px;
        }
        .insurance-sub-order {
          .insurance-sub-list {
            .insurance-sub-block {
              display: grid;
              grid-template-columns: 1.5fr 3fr;
            }
          }
        }

        .main-sub-block {
          .insurance-sub-block {
            border: 1.5px solid #e7e7f7;
            margin: 5px 0;
            overflow: hidden;
            border-radius: 10px;
            &.m-10 {
              margin: 10px;
            }
          }
          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(12),
          &:nth-child(13) {
            .Patient-details {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              column-gap: 20px;
              row-gap: 5px;
              color: #000000;
              margin: 0 10px;
              li {
                border: 1.5px solid #e7e7f7;
                margin: 5px 0;
                overflow: hidden;
                border-radius: 10px;
                span:nth-child(2) {
                  padding: 5px 15px;
                  display: inline-flex;
                }
              }
              .insurance-label {
                color: #273480;
                font-weight: 500;
              }
            }
            .insurance-sub-block {
              display: block;
            }
          }
          &:nth-child(16) {
            .insurance-sub-block {
              display: block;
            }
            .Patient-details {
              margin: 0 10px;
              grid-template-columns: 1fr;
              li {
                border: 1.5px solid #e7e7f7;
                margin: 5px 0;
                overflow: hidden;
                border-radius: 10px;
                span:nth-child(2) {
                  padding: 5px 15px;
                  display: inline-flex;
                }
              }
            }
          }
        }
        &:nth-child(7) {
          grid-column: 1 / span 3;
          .insurance-block {
            display: block;
            .insurance-sub-order {
              grid-template-columns: 1fr;
            }
            .insurance-sub-list {
              display: grid;
              grid-template-columns: 1fr;
              column-gap: 20px;
              margin: 10px;
            }
          }
        }
      }
    }
  }
  .sub-details-block {
    .Patient-details {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 5px;
      color: #000000;
      margin: 10px;
      li {
        border: 1.5px solid #e7e7f7;
        margin: 5px 0;
        overflow: hidden;
        border-radius: 10px;
        span:nth-child(2) {
          padding: 5px 15px;
          display: inline-flex;
        }
      }
      .insurance-label {
        color: #273480;
        font-weight: 500;
      }
    }
  }
}
</style>
