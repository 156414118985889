<template>
  <div class="default-modal">
    <el-dialog
      top="25vh"
      width="36.5%"
      v-model="visible"
      :title="displayTitle"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="dialog-header">
        <span class="header-part">
          {{ displayTitle }}
        </span>
        <el-button
          size="medium"
          class="el-white"
          type="info"
          plain
          @click="close"
        >
          <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
        /></el-button>
      </div>
      <div class="popup-date pb-0">
        <el-form ref="ruleForm" :model="formData">
          <el-form-item prop="reason">
            <el-input
              type="textarea"
              v-model="formData.reason"
              rows="3"
              placeholder="Enter reason"
            />
            <p><b>Note :</b> Minimum 3 characters is required</p>
          </el-form-item>
        </el-form>
        <div class="dialog-footer pt-0">
          <el-button
            class="blue-save-button"
            :disabled="checkReason"
            @click="submit('ruleForm')"
            >SUBMIT</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
    },
    width: {
      default: 30,
    },
  },
  data() {
    return {
      displayTitle: this.title ? this.title : "Reason for change",
      visible: true,
      currentWidth: "30%",
      formData: {
        reason: "",
      },
      rules: {
        reason: [
          {
            required: true,
            message: "Invalid  reason",
            pattern: /^.\S/,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.width) {
      this.currentWidth = this.width + "%";
    }
  },
  computed: {
    checkReason() {
      if (
        this.formData.reason &&
        this.formData.reason.trim() &&
        this.formData.reason.trim().length &&
        this.formData.reason.trim().length >= 3
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visible = false;

          this.$emit("reasonSubmit", this.formData.reason);
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss"></style>
