<template>
  <div class="default-modal">
    <el-dialog
      v-model="visible"
      top="5vh"
      width="55%"
      ;
      :title="displayTitle"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="dialog-header">
        <span class="header-part"> Attention! </span>
        <el-button
          size="medium"
          class="el-white"
          type="info"
          plain
          @click="close"
        >
          <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
        /></el-button>
      </div>
      <div class="icdAttentionPopup pb-0">
        <div class="icdAttentionPopupBlock" v-if="validationErrors">
          <ul class="attentionlists">
            <li
              class="eachLists"
              v-for="(error, index) in validationErrors"
              :key="index"
            >
              <div class="icdValidations">
                <ul>
                  <!-- <li v-if="error.errors">
                  <span>Error Message:</span> {{ error.errors.errorMessage }}
                </li> -->
                  <li v-if="error.errorCode == 'BILLING_INFO_MISSING'">
                    <p class="pt-10" style="color: #ff4141">
                      {{ error.message }}
                    </p>
                  </li>
                  <li
                    class="eachLists"
                    v-if="error.errorCode == 'INSURANCE_VERIFICATION_FAILED'"
                  >
                    <div class="title">
                      <img src="@/assets/images/icons/caution-icon.svg" />
                      <h3>Insurance Verification Failed!</h3>
                    </div>
                    <p class="para">
                      Insurance verification for this case failed due to errors.
                      Please make sure all details are correct to proceed with
                      insurance verification.
                    </p>
                  </li>
                  <li
                    class="eachLists"
                    v-if="error.errorCode == 'PAYABLE_CODE_NOT_EXISTS'"
                  >
                    <div class="title">
                      <img src="@/assets/images/icons/caution-icon.svg" />
                      <h3>ICD Codes Error</h3>
                    </div>
                    <p class="para">
                      Non-billable ICD codes identified. Please verify and use
                      eligible codes from the list below.
                    </p>
                  </li>
                  <p class="pt-10" style="color: #ff4141">
                    {{ error.errors ? error.errors.errorMessage : "" }}
                  </p>

                  <li
                    class="notMatched"
                    v-if="
                      error.errors &&
                      error.errors.not_matched_values &&
                      error.errors.not_matched_values.length > 0
                    "
                  >
                    <div class="headerBlock">
                      <img src="@/assets/images/icons/mini-error.svg" />
                      <span class="header">Not Matched Values :</span>
                    </div>

                    <ul>
                      <li
                        v-for="(value, index) in error.errors
                          .not_matched_values"
                        :key="index"
                      >
                        {{ value }}
                      </li>
                    </ul>
                  </li>
                  <li
                    class="Matched"
                    v-if="
                      error.errors &&
                      error.errors.payable_codes &&
                      error.errors.payable_codes.length > 0
                    "
                  >
                    <div class="headerBlock">
                      <img src="@/assets/images/icons/mini-success.svg" />
                      <span class="header"
                        >Payable Codes :
                        {{
                          caseTypeDisplayName(error.errors.original.case_types)
                        }}</span
                      >
                    </div>
                    <ul>
                      <li
                        v-for="(code, index) in error.errors.payable_codes"
                        :key="index"
                      >
                        {{ code }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <p class="para pb-10">
            Please review and correct errors to continue with insurance
            verification.
          </p>
        </div>
        <div class="dialog-footer pt-0">
          <el-button class="cancel-button" @click="close">Cancel</el-button>
          <!-- <el-button
            class="blue-save-button"
            @click="close"
            v-if="this.caseCreation"
            >Move To Pending Cases</el-button
          > -->
          <el-button
            class="blue-save-button"
            @click="moveToPending"
            v-if="this.convert"
            :loading="loading"
            >Move To Pending</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
  <ReasonForStatusChange
    v-if="takeStatusChangeReasonDialog"
    v-on:close="takeStatusChangeReasonDialog = false"
    v-on:reasonSubmit="unsetOrderCompleteStatus"
    :title="takeStatusChangeReasonDialogTitle"
    :width="40"
  ></ReasonForStatusChange>
</template>

<script>
import ReasonForStatusChange from "@/components/misc/OrderReasonForChange";
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";

export default {
  props: ["validationErrors", "caseCreation", "convert"],
  components: {
    ReasonForStatusChange,
  },
  data() {
    return {
      visible: true,
      currentWidth: "30%",
      formData: {
        reason: "",
      },
      rules: {
        reason: [
          {
            required: true,
            message: "Invalid  reason",
            pattern: /^.\S/,
            trigger: "blur",
          },
        ],
      },
      takeStatusChangeReasonDialog: false,
      takeStatusChangeReasonDialogTitle: "PENDING",
      toStatus: "PENDING",
      loading: false,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
    };
  },
  mounted() {
    if (this.width) {
      this.currentWidth = this.width + "%";
    }
  },
  computed: {
    ...mapGetters("orders", ["getUpdateOrderStatus"]),
    getCaseTypeMapped(case_type) {
      return this.caseTypeMap[case_type] || "";
    },
    checkReason() {
      if (
        this.formData.reason &&
        this.formData.reason.trim() &&
        this.formData.reason.trim().length &&
        this.formData.reason.trim().length >= 3
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    caseTypeDisplayName(caseType) {
      return this.caseTypeMap[caseType] || caseType;
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visible = false;

          this.$emit("reasonSubmit", this.formData.reason);
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    moveToPending() {
      this.takeStatusChangeReasonDialog = true;
    },
    async unsetOrderCompleteStatus(data) {
      let params = {
        id: this.$route.params.order_id,
        status: this.toStatus,
        notes: data.notes,
      };
      if (data.reasons && data.reasons.length > 0) {
        params.reasons = data.reasons;
      }
      await this.updateStatus(params);
    },
    async updateStatus(params) {
      try {
        this.loading = true;
        await this.$store.dispatch("orders/changeOrderStatus", params, {
          root: true,
        });
        if (this.getUpdateOrderStatus && this.getUpdateOrderStatus.success) {
          this.$notify({
            type: "success",
            title: "Success",
            message: this.getUpdateOrderStatus.message,
          });
          window.location.reload();
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
